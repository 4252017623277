import axios, { type AxiosInstance } from "axios";
import { useToast } from "vue-toastification";
import type { ErrorResponse, SuccessResponse } from "~/types/response.types";
import { usePrescriberStore } from "~/stores/prescriber.store";

let instance: AxiosInstance;

export function createAxiosInstance() {
  const baseURL = getBaseURL();

  instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(async (config) => {
    const router = useRouter();
    const controller = new AbortController();
    try {
      const token = await getAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      controller.abort();
      useToast().info("Your login has expired. Please log in again.");
      await router.push("/login");
    }
    return {
      ...config,
      signal: controller.signal,
    };
  });

  instance.interceptors.response.use(
    (response) => {
      const toast = useToast();
      const { message } = response.data as SuccessResponse;
      message && toast.success(message);
      return response;
    },
    (err) => {
      const { message, error } = err.response.data as ErrorResponse;
      message ? useToast().error(message) : useToast().error(err.message);
      return Promise.reject(error);
    },
  );
}

export const client = {
  async get<T>(url: string) {
    const response = await instance.get<T>(url);
    return response?.data;
  },

  async post<T>(url: string, data: any) {
    const response = await instance.post<T>(url, data);
    return response?.data;
  },

  async patch<T>(url: string, data: any) {
    const response = await instance.patch<T>(url, data);
    return response?.data;
  },

  async put<T>(url: string, data: any) {
    const response = await instance.put<T>(url, data);
    return response?.data;
  },

  async delete<T>(url: string) {
    const response = await instance.delete<T>(url);
    return response?.data;
  },
};

export const getBaseURL = () => {
  const { public: env } = useRuntimeConfig();
  return `${env.API_URL}/prescriber/v1`;
};

const getAccessToken = async (): Promise<string> => {
  const { data } = await useSupabaseClient().auth.getSession();
  // Get access token from the session
  const accessToken = data?.session?.access_token;
  if (!accessToken) throw new Error("Access token could not be fetched");
  // Get user role from the session and set in the store
  const userRole = data?.session?.user?.app_metadata?.role;
  usePrescriberStore().setUserRole(userRole);
  return accessToken;
};
