import { defineStore } from "pinia";

const fallbackRole = "prescriber";

export const usePrescriberStore = defineStore({
  id: "prescriber",
  state: () => ({
    role: fallbackRole as "prescriber" | "assistant",
  }),
  actions: {
    setUserRole(role: any) {
      if (["prescriber", "assistant"].includes(role)) {
        this.role = role;
      }
    },
  },
});
